import { isElement } from "@udecode/plate";
import { createEditor, Descendant, Editor, Transforms } from "slate";

export function getSimpleEditorTextOrLegacyText({
  nodeStr,
  document,
  defaultValue = "Untitled",
}: {
  nodeStr?: string | null;
  document?: Array<Descendant>;
  defaultValue?: string;
}) {
  if (nodeStr) {
    try {
      // TODO: figure out why nodeStr is sometimes a string and sometimes an object
      const rootNode =
        typeof nodeStr === "string" ? JSON.parse(nodeStr) : nodeStr;
      const tmpEditor = createEditor();
      tmpEditor.children = rootNode;
      tmpEditor.normalizeNode = (entry) => entry;
      return Editor.string(tmpEditor, []) || defaultValue;
    } catch (err) {
      return nodeStr;
    }
  }

  if (document?.length) {
    const tmpEditor = createEditor();
    tmpEditor.children = document;

    tmpEditor.normalizeNode = ([node, path]) => {
      if (isElement(node) && node.type === "changeset" && node.removed) {
        Transforms.removeNodes(tmpEditor, { at: path });
        return;
      }
    };

    Editor.normalize(tmpEditor, { force: true });
    return Editor.string(tmpEditor, []) || defaultValue;
  } else {
    return defaultValue;
  }
}
