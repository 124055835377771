import { ApolloError, useMutation } from "@apollo/client";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Popover from "@mui/material/Popover";
import { AnimatePresence, motion } from "framer-motion";
import { FC, useCallback, useMemo, useRef, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { css } from "styled-components";

import AddUserIcon from "~/assets/icons/add-user.svg";
import CheckIcon from "~/assets/icons/check-stroke.svg";
import LinkIcon from "~/assets/icons/link.svg";
import EmailInput from "~/browser/workspace-settings/components/EmailInput";
import Button from "~/components/Button";
import { ButtonVariant } from "~/components/Button/types";
import { CopyToClipboardIcon } from "~/components/CopyToClipboardIcon";
import { ConfirmationDialog } from "~/components/ProposerModals/ProposeModal/ConfirmationDialog";
import { useWorkspaceContext } from "~/contexts/WorkspaceContext";
import {
  EmailInvite,
  useInviteGuestsToWorkspaceMutation,
} from "~/graphql/generated";
import { useWorkspaceLinkInvitation } from "~/graphql/hooks/workspace/useWorkspaceLinkInvitation";
import { INVITE_MEMBERS } from "~/graphql/mutations/workspace/inviteMembers";
import { inflect } from "~/utils/common";

const inflectEmail = inflect("Email", "Emails");

const InviteLink: FC<{ linkId?: string }> = ({ linkId }) => {
  const [copied, setCopied] = useState(false);
  const handleCopy = useCallback(() => {
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  }, []);

  const shortLinkUrl = linkId ? `${location.host}/invitation/${linkId}` : "";
  const linkUrl = shortLinkUrl ? `${location.protocol}//${shortLinkUrl}` : "";

  return (
    <div
      css={css`
        padding: 0 0.5rem;
        text-align: center;
        font-size: 0.875rem;
      `}
    >
      {linkUrl ? (
        <CopyToClipboard text={linkUrl} onCopy={handleCopy}>
          <div
            css={css`
              margin-top: 1rem;
              margin-bottom: 1rem;
            `}
          >
            <button
              type="button"
              css={css`
                display: inline-flex;
                align-items: center;
                gap: 0.5rem;
                background: ${({ theme }) => theme.colors.moonLight};
                padding: 1rem 1.5rem;
                border: 1px solid ${({ theme }) => theme.colors.moon};
                cursor: pointer;
                &:hover {
                  background-color: ${({ theme }) => theme.colors.speech10};
                  border: 1px dashed ${({ theme }) => theme.colors.speech};
                }
              `}
            >
              <LinkIcon
                css={css`
                  color: ${({ theme }) => theme.colors.speech};
                  height: 1.25rem;
                `}
              />
              <span>{shortLinkUrl}</span>
            </button>
            <CopyToClipboardIcon
              copied={copied}
              css={css`
                font-size: 0.75rem;
                padding: 0.5rem 1rem;
              `}
            />
          </div>
        </CopyToClipboard>
      ) : (
        <div
          css={css`
            margin-top: 1rem;
            margin-bottom: 1rem;
          `}
        >
          <div
            css={css`
              display: inline-flex;
              align-items: center;
              gap: 0.5rem;
              background: ${({ theme }) => theme.colors.moonLight};
              padding: 1rem 1.5rem;
              border: 1px solid ${({ theme }) => theme.colors.moon};
              cursor: not-allowed;
            `}
          >
            <LinkIcon
              css={css`
                color: ${({ theme }) => theme.colors.oil};
                height: 1.25rem;
              `}
            />
            <span>Generating link…</span>
          </div>
        </div>
      )}
    </div>
  );
};

const InviteToWorkspaceModal: FC<{
  buttonLabel?: string;
  buttonVariant?: ButtonVariant;
  buttonIcon?: JSX.Element;
}> = ({
  buttonLabel = "Invite",
  buttonVariant,
  buttonIcon = <AddUserIcon width={14} />,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLink, setSelectedLink] = useState("member");
  const { workspace, slug } = useWorkspaceContext();
  const [emails, setEmails] = useState<Map<string, EmailInvite>>(new Map());
  const [recentlySent, setRecentlySent] = useState<Map<string, EmailInvite>>(
    new Map()
  );
  const [sendingInvites, setSendingInvites] = useState(false);
  const [
    showConfirmationAnchor,
    setShowConfirmationAnchor,
  ] = useState<HTMLElement | null>(null);
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const disableSubmit = sendingInvites;

  const workspaceMemberInviteResult = useWorkspaceLinkInvitation({
    slug,
    guest: false,
  });
  const workspaceGuestInviteResult = useWorkspaceLinkInvitation({
    slug,
    guest: true,
  });

  const linkError = useMemo(() => {
    return (
      (selectedLink === "guest" &&
        workspaceGuestInviteResult?.context instanceof ApolloError) ||
      (selectedLink === "member" &&
        workspaceMemberInviteResult?.context instanceof ApolloError)
    );
  }, [
    selectedLink,
    workspaceGuestInviteResult?.context,
    workspaceMemberInviteResult?.context,
  ]);

  const linkId = useMemo(() => {
    switch (selectedLink) {
      case "guest":
        if (workspaceGuestInviteResult?.context instanceof ApolloError) {
          return;
        }
        return workspaceGuestInviteResult?.context?.workspaceInvitationLink
          ?.linkId;
      case "member":
        if (workspaceMemberInviteResult?.context instanceof ApolloError) {
          return;
        }
        return workspaceMemberInviteResult?.context?.workspaceInvitationLink
          ?.linkId;
    }
    return;
  }, [
    selectedLink,
    workspaceGuestInviteResult?.context,
    workspaceMemberInviteResult?.context,
  ]);

  const showInviteDialog = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleLinkChange = useCallback((evt) => {
    setSelectedLink(evt.target.value);
  }, []);

  const handleEmailInputChange = useCallback((emails) => {
    setRecentlySent(new Map());
    setEmails(emails);
  }, []);

  const handleClose = useCallback(() => {
    if (sendingInvites) return;
    setEmails(new Map());
    setIsOpen(false);
    setShowConfirmationAnchor(null);
  }, [sendingInvites]);

  const handleConfirmClose = useCallback(() => {
    if (sendingInvites) return;
    if (emails.size) {
      setShowConfirmationAnchor(submitButtonRef?.current ?? null);
      return;
    }
    handleClose();
  }, [emails.size, handleClose, sendingInvites]);

  const [inviteTeamMembers] = useMutation(INVITE_MEMBERS);
  const [inviteGuests] = useInviteGuestsToWorkspaceMutation();

  const handleSendInvites = useCallback(async () => {
    setSendingInvites(true);

    const guestEmails = Array.from(emails.values())
      .filter((value) => value.workspaceGuest)
      .map((value) => value.email);
    const memberEmails = Array.from(emails.values())
      .filter((value) => !value.workspaceGuest)
      .map((value) => value.email);

    await Promise.all([
      ...(guestEmails.length
        ? [
            inviteGuests({
              variables: {
                input: {
                  workspaceId: slug,
                  emailAddresses: guestEmails,
                },
              },
            }),
          ]
        : []),
      ...(memberEmails.length
        ? [
            inviteTeamMembers({
              variables: {
                input: {
                  workspaceId: slug,
                  emailAddresses: memberEmails,
                },
              },
            }),
          ]
        : []),
    ]);

    // TODO: check for errors

    setRecentlySent(emails);
    setEmails(new Map());
    setSendingInvites(false);
  }, [emails, inviteGuests, inviteTeamMembers, slug]);

  const handlePrimaryButton = useCallback(async () => {
    if (emails.size) {
      await handleSendInvites();
    } else {
      handleClose();
    }
  }, [emails.size, handleClose, handleSendInvites]);

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirmationAnchor(null);
  }, []);

  if (!workspace) {
    return null;
  }

  return (
    <>
      <Button
        variant={buttonVariant}
        css={css`
          display: flex;
          align-items: center;
          gap: 0.25rem;
        `}
        onClick={showInviteDialog}
        {...rest}
      >
        {buttonIcon}
        <div>{buttonLabel}</div>
      </Button>
      <Dialog
        onClose={handleConfirmClose}
        open={isOpen}
        css={css`
          & .MuiPaper-root {
            padding: 1rem 0.75rem 0;
          }
        `}
      >
        <DialogTitle>Invite to {workspace.name}</DialogTitle>
        <DialogContent
          css={css`
            margin: 0;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            min-width: 35rem;
            width: 35rem;
            max-width: 35rem;
          `}
        >
          <div
            css={css`
              border: none;
              border-top: ${({ theme }) => theme.colors.moon} 1px solid;
              padding-top: 1rem;
            `}
          >
            <div>
              <h3
                css={css`
                  margin: 0;
                  padding: 0.25rem 0;
                `}
              >
                Invite by Link
              </h3>
            </div>
            <div
              css={css`
                font-size: 0.875rem;
                padding: 0.25rem 0;
              `}
            >
              Anyone using this link will join this workspace as a{" "}
              <select
                css={css`
                  border: none;
                  font-weight: ${({ theme }) => theme.weights.medium};
                `}
                value={selectedLink}
                onChange={handleLinkChange}
              >
                <option value="guest">guest</option>
                <option value="member">member</option>
              </select>
            </div>
            {linkError ? (
              <div
                css={css`
                  font-size: 0.875rem;
                  background-color: ${({ theme }) =>
                    theme.colors.cinnabarLight};
                  border-radius: 0.25rem;
                  padding: 0.625rem 1rem;
                  margin: 0.5rem 0;
                `}
              >
                An error occurred loading the link. Please try again later.
              </div>
            ) : (
              <InviteLink linkId={linkId} />
            )}
          </div>
          <div
            css={css`
              border: none;
              border-top: ${({ theme }) => theme.colors.moon} 1px solid;
              padding-top: 1rem;
            `}
          >
            <h3
              css={css`
                margin: 0;
                padding: 0.25rem 0;
              `}
            >
              Invite by Email
            </h3>
            <div
              css={css`
                font-size: 0.875rem;
                padding: 0.25rem 0;
              `}
            >
              Enter the email addresses of the people you'd like to invite to
              your workspace.
            </div>
            <EmailInput
              emails={emails}
              onChange={handleEmailInputChange}
              disabled={sendingInvites}
            />
            <AnimatePresence>
              {!!recentlySent.size && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  css={css`
                    display: flex;
                    align-items: start;
                    gap: 0.5rem;
                    padding: 0.75rem 1rem;
                    line-height: 1.4;
                    border-radius: 0.25rem;
                    background-color: ${({ theme }) => theme.colors.speech10};
                  `}
                >
                  <CheckIcon
                    css={css`
                      margin-top: 0.1875rem;
                      color: ${({ theme }) => theme.colors.speech};
                      width: 1rem;
                      height: 1rem;
                    `}
                  />
                  <div>
                    {inflectEmail(recentlySent.size)} sent to:{" "}
                    {Array.from(recentlySent.keys()).join(", ")}
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <div
            css={css`
              display: flex;
              justify-content: end;
              margin: 0.5rem 0;
              gap: 0.5rem;
            `}
          >
            {!!emails.size && (
              <Button
                disabled={sendingInvites}
                variant="secondary"
                css={css`
                  width: auto;
                `}
                onClick={handleConfirmClose}
              >
                Cancel
              </Button>
            )}
            <Button
              ref={submitButtonRef}
              disabled={disableSubmit}
              variant={emails.size ? "speech" : "primary"}
              css={css`
                width: auto;
              `}
              onClick={handlePrimaryButton}
            >
              {emails.size
                ? sendingInvites
                  ? `Sending ${emails.size} Invites…`
                  : "Send Invites"
                : "Done"}
            </Button>
            <Popover
              open={!!showConfirmationAnchor}
              anchorEl={showConfirmationAnchor}
              onClose={handleDismissConfirmation}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <ConfirmationDialog
                title="Discard invites?"
                message="You entered email invites, but have not sent them yet. Do you want to discard them?"
                confirmButtonLabel="Discard Invites"
                confirmButtonVariant="danger"
                onCancel={handleDismissConfirmation}
                onConfirm={handleClose}
              />
            </Popover>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default InviteToWorkspaceModal;
