import React, { useCallback, useEffect, useRef } from "react";
import type { FC } from "react";

import { Container } from "./styles";
import type { Props } from "./types";

const ClickAwayListener: FC<Props> = ({ children, onClickAway, ...rest }) => {
  const ref = useRef<HTMLDivElement>(null);

  const onMouseDown = useCallback(
    (e: MouseEvent) => {
      if (
        ref.current &&
        e.target instanceof Node &&
        !ref.current.contains(e.target)
      ) {
        onClickAway(e);
      }
    },
    [onClickAway, ref]
  );

  useEffect(() => {
    document.addEventListener("mousedown", onMouseDown, { capture: true });

    return () => {
      document.removeEventListener("mousedown", onMouseDown, { capture: true });
    };
  }, [onMouseDown]);

  return (
    <Container ref={ref} {...rest}>
      {children}
    </Container>
  );
};

export default ClickAwayListener;
