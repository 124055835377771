import DefaultAvatar from "@mui/material/Avatar";
import type { CSSProperties, FC } from "react";
import React from "react";
import styled, { useTheme } from "styled-components";

import WorkspaceIcon from "~/assets/icons/workspace.svg";
import { GroupType, Maybe } from "~/graphql/generated";
import { Group } from "~/model/group";
import { getSimpleEditorTextOrLegacyText } from "~/utils/getTextFromRootNode";
import { TShirtSize, TShirtSizes } from "~/utils/TShirtSizes";

export type AvatarUser = {
  _id: string;
  avatar?: Maybe<string>;
  displayName?: Maybe<string>;
};
export type AvatarGroup = Pick<Group, "name" | "icon" | "type">;
export const AvatarSizeStyles: { [key in TShirtSize]: CSSProperties } = {
  tiny: { ...TShirtSizes.tiny, fontSize: "0.75rem", lineHeight: "1rem" },
  xs: { ...TShirtSizes.xs, fontSize: "0.875rem" },
  sm: { ...TShirtSizes.sm, fontSize: "1rem" },
  smmd: { ...TShirtSizes.smmd, fontSize: "1.125rem" },
  md: { ...TShirtSizes.md, fontSize: "1.25rem" },
  lg: { ...TShirtSizes.lg, fontSize: "1.5rem" },
  xl: { ...TShirtSizes.xl, fontSize: "2rem" },
  "2xl": { ...TShirtSizes["2xl"], fontSize: "3rem" },
  "3xl": { ...TShirtSizes["3xl"], fontSize: "9rem" },
};

export const Avatar: FC<{
  alt: string;
  src: string;
  size?: TShirtSize;
  style?: CSSProperties;
}> = ({ alt, src, size = "md", style, ...rest }) => {
  const theme = useTheme();
  return (
    <DefaultAvatar
      {...rest}
      alt={alt}
      title={alt}
      src={src}
      style={{
        ...AvatarSizeStyles[size],
        textTransform: "uppercase",
        color: theme.colors.white,
        fontWeight: theme.weights.medium,
        ...(!src ? { backgroundColor: theme.colors.speech } : {}),
        ...style,
      }}
    >
      {alt[0]}
    </DefaultAvatar>
  );
};

export const UserAvatar: FC<{
  user?: AvatarUser;
  size?: TShirtSize;
  style?: CSSProperties;
}> = ({ user, size = "md", style, ...rest }) => {
  const alt = user?.displayName ? user.displayName : ``;
  const src = user?.avatar ? user.avatar : ``;

  return (
    <Avatar
      alt={alt}
      src={src}
      style={{ ...AvatarSizeStyles[size], ...style }}
      {...rest}
    >
      {alt[0]}
    </Avatar>
  );
};

export const GroupAvatar: FC<{
  group: AvatarGroup;
  size?: TShirtSize;
  style?: CSSProperties;
}> = ({ group, size = "md", style, ...rest }) => {
  const alt = getSimpleEditorTextOrLegacyText({
    nodeStr: group.name,
    defaultValue: "",
  });
  const src = group.icon || ``;

  if (group.type === GroupType.WorkspaceMembership) {
    return (
      <IconAvatar name={alt} style={{ ...AvatarSizeStyles[size], ...style }}>
        <WorkspaceIcon width="32" height="32" />
      </IconAvatar>
    );
  }

  return (
    <Avatar
      alt={alt}
      src={src}
      style={{ ...AvatarSizeStyles[size], ...style }}
      {...rest}
    >
      {alt[0]}
    </Avatar>
  );
};

const StyledCircle = styled.div`
  display: inline-flex;
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: ${({ theme }) => theme.colors.speech};
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.white};
  & svg {
    transform: scale(50%);
  }
`;

export const IconAvatar: FC<{
  name: string;
  size?: TShirtSize;
  style?: CSSProperties;
}> = ({ children, name, size = "md", style, ...rest }) => {
  return (
    <StyledCircle
      title={name}
      style={{ ...AvatarSizeStyles[size], ...style }}
      {...rest}
    >
      {children}
    </StyledCircle>
  );
};

export default UserAvatar;
