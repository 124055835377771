import { PositioningPortal } from "@codastic/react-positioning-portal";
import { FC, SyntheticEvent, useCallback } from "react";
import { useMemo, useState } from "react";

import { HorizontalEllipsis } from "~/components/Icons";

import { Container, IconButton, Menu, MenuButton, MenuItem } from "./styles";
import type { Props } from "./types";

const alwaysTrue = () => true;

const getAriaLabelForToggleButton = (isOpen: boolean) =>
  isOpen ? "Close Menu" : "Open Menu";

const ActionMenu: FC<Props> = ({
  Icon = HorizontalEllipsis,
  label,
  items,
  zIndex,
  style,
  ...rest
}) => {
  const [isOpen, { closeMenu, openMenu, toggleMenu }] = useActionMenu();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const onKeyUp = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      const menuLength = items.length;
      switch (event.key.toLocaleLowerCase()) {
        case "arrowup":
          setSelectedIndex((selectedIndex - 1) % menuLength);
          break;
        case "arrowdown":
          setSelectedIndex((selectedIndex + 1) % menuLength);
          break;
        case "enter":
          items[selectedIndex]?.action();
          break;
      }
    },
    [selectedIndex, setSelectedIndex, items]
  );

  return (
    <PositioningPortal
      isOpen={isOpen}
      onOpen={openMenu}
      onShouldClose={closeMenu}
      portalContent={
        <Menu zIndex={zIndex}>
          {items.map(
            (
              { text, action, condition = alwaysTrue, disabled = false },
              index: number
            ) => {
              if (!condition()) return null;

              return (
                <MenuItem key={text}>
                  <MenuButton
                    onClick={(event: SyntheticEvent) => {
                      event?.stopPropagation();
                      event?.preventDefault();
                      action();
                      closeMenu();
                    }}
                    type="button"
                    selected={index === selectedIndex}
                    disabled={disabled}
                  >
                    {text}
                  </MenuButton>
                </MenuItem>
              );
            }
          )}
        </Menu>
      }
    >
      <Container style={{ ...style }} onKeyUp={onKeyUp} {...rest}>
        <IconButton
          aria-expanded={isOpen}
          aria-label={getAriaLabelForToggleButton(isOpen)}
          onClick={toggleMenu}
          type="button"
        >
          <Icon />
          {label && <span>{label}</span>}
        </IconButton>
      </Container>
    </PositioningPortal>
  );
};

export default ActionMenu;

type UseActionMenuTuple = [
  state: boolean,
  handlers: {
    closeMenu: () => void;
    openMenu: () => void;
    toggleMenu: (event?: SyntheticEvent) => void;
  }
];

function useActionMenu(): UseActionMenuTuple {
  const [state, setState] = useState(false);

  const handlers = useMemo(
    () => ({
      closeMenu: () => {
        setState(false);
      },
      openMenu: () => {
        setState(true);
      },
      toggleMenu: (event?: SyntheticEvent) => {
        event?.stopPropagation();
        event?.preventDefault();
        setState((s) => !s);
      },
    }),
    []
  );

  return [state, handlers];
}
