import type { QueryHookOptions } from "@apollo/client";

import type { Workspace } from "~/graphql/generated";
import { GET_WORKSPACE } from "~/graphql/queries/workspace/getWorkspace";
import usePageVisibility from "~/hooks/usePageVisibility";
import useQueryMachine from "~/hooks/useQueryMachine";
import { useUserInWorkspace } from "~/hooks/useUserInWorkspace";
import { normalizeWorkspace } from "~/model/workspace";
import { withoutNulls } from "~/utils/withoutNulls";

export const useWorkspace = (slug: string, options: QueryHookOptions = {}) => {
  const isVisible = usePageVisibility();
  // TODO handle unauthorized responses from the backend

  const [userInWorkspace, userIsGuestInWorkspace] = useUserInWorkspace({
    workspaceId: slug,
  });

  const result = useQueryMachine<{ workspace: Workspace }>(GET_WORKSPACE, {
    variables: {
      params: { workspaceId: slug },
    },
    nextFetchPolicy: "cache-first",
    pollInterval:
      isVisible && process.env.NEXT_PUBLIC_FLAG_SHOULD_POLL ? 30000 : 0,
    ...options,
    skip: !(slug && (userInWorkspace || userIsGuestInWorkspace)),
  });

  if (result.state === "SUCCESS") {
    const data = withoutNulls(result.context?.workspace);
    const normalized = data && normalizeWorkspace(data);

    return { state: result.state, context: normalized };
  }

  if (result.state === "LOADING") {
    const data = withoutNulls(result.context?.workspace);
    const normalized = data && normalizeWorkspace(data);

    return { state: result.state, context: normalized };
  }

  return result;
};
