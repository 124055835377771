export const TShirtSizes = {
  tiny: { width: 16, height: 16 },
  xs: { width: 20, height: 20 },
  sm: { width: 24, height: 24 },
  smmd: { width: 28, height: 28 },
  md: { width: 36, height: 36 },
  lg: { width: 48, height: 48 },
  xl: { width: 76, height: 76 },
  "2xl": { width: 128, height: 128 },
  "3xl": { width: 144, height: 144 },
};

export type TShirtSize = keyof typeof TShirtSizes;
