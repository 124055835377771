import { css } from "styled-components";

import { ButtonVariant } from "~/components/Button/types";

import { Button } from "../../Modal/styles";

export const ConfirmationDialog = ({
  title,
  message,
  cancelButtonLabel = "Go Back",
  confirmButtonLabel = "Continue",
  confirmButtonVariant = "speech",
  onCancel,
  onConfirm,
}: {
  title: string;
  message: string;
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
  confirmButtonVariant?: ButtonVariant;
  onCancel: () => void;
  onConfirm: () => void;
}) => {
  return (
    <div
      css={css`
        width: 39rem;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        color: ${({ theme }) => theme.colors.oil};
      `}
    >
      <div
        css={css`
          margin: 0.5rem 0.5rem 0.25rem 0.5rem;
          font-size: 1.125rem;
          font-weight: ${({ theme }) => theme.weights.medium};
        `}
      >
        {title}
      </div>
      <div
        css={css`
          margin: 0 0.5rem 0.25rem 0.5rem;
          font-size: 0.9375rem;
          color: ${({ theme }) => theme.colors.saltBox};
        `}
      >
        {message}
      </div>
      <div
        css={css`
          margin-top: 0.75rem;
          display: flex;
          justify-content: flex-end;
          gap: 0.5rem;
          font-size: 0.875rem;
        `}
      >
        <Button variant="secondary" onClick={onCancel}>
          {cancelButtonLabel}
        </Button>
        <Button variant={confirmButtonVariant} onClick={onConfirm} autoFocus>
          {confirmButtonLabel}
        </Button>
      </div>
    </div>
  );
};
