import { gql } from "@apollo/client";
import type { TypedDocumentNode } from "@apollo/client";

import type {
  InviteMembersMutation,
  InviteMembersMutationVariables,
} from "~/graphql/generated";

export const INVITE_MEMBERS: TypedDocumentNode<
  InviteMembersMutation,
  InviteMembersMutationVariables
> = gql`
  mutation InviteMembers($input: InviteMembersToWorkspaceInput!) {
    inviteMembersToWorkspace(input: $input) {
      _id
      name
      invitedEmails {
        email
        workspaceGuest
      }
    }
  }
`;
