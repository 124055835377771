import { gql } from "@apollo/client";

export const FULL_MEMBER_FRAGMENT = gql`
  fragment FullMember on Member {
    _id
    permission
    user {
      avatar
      displayName
      _id
      email
      lastActive
      joined
    }
  }
`;
