import { gql } from "@apollo/client";

export const GOVERNS_FRAGMENT = gql`
  fragment Governs on Governed {
    users {
      _id
      displayName
      avatar
    }
    groups {
      _id
      name
      type
      icon
      description
    }
  }
`;

export const GOVERNS_WITH_MEMBERS_FRAGMENT = gql`
  fragment GovernsWithMembers on Governed {
    users {
      _id
      displayName
      avatar
    }
    groups {
      _id
      name
      type
      icon
      description
      members {
        user {
          _id
        }
      }
    }
  }
`;
