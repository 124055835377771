import type { Identified } from "./ref";

/**
 * refString is like ref, but returns a string (instead of an ObjectID like ref does).
 */
export function refString<T extends Identified>(objOrId?: T): string {
  if (!objOrId) {
    return "";
  }
  const asObject = objOrId as { _id: string };
  return (asObject._id || objOrId).toString();
}
