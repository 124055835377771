import { useEffect, useState } from "react";

export default function usePageVisibility() {
  const [isVisible, setIsVisible] = useState(() => {
    // if it's not the browser, there's no document, return false
    if (!process.browser) return false;

    return !document.hidden;
  });

  const handleVisibilityChange = () => {
    setIsVisible(!document.hidden);
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [setIsVisible]);

  return isVisible;
}
