import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import { GroupRole } from "~/graphql/generated";
import { useWorkspace } from "~/graphql/hooks/workspace/useWorkspace";
import type { Workspace } from "~/model/workspace";

import { useAnalytics } from "../AnalyticsContext";
import { useUserContext } from "../UserContext";

type WorkspaceCtx = {
  workspace?: Workspace;
  slug: string;
  isAdmin: boolean;
  isBillingAdmin: boolean;
  isMember: boolean;
  isGuest: boolean;
  state: "IDLE" | "LOADING" | "SUCCESS" | "FAILURE";
};
const WorkspaceContext = createContext<WorkspaceCtx>({
  slug: "",
  isAdmin: false,
  isBillingAdmin: false,
  isMember: false,
  isGuest: false,
  state: "IDLE",
});

export const WorkspaceProvider: FC<{ slug: string; children: ReactNode }> = ({
  slug,
  children,
}) => {
  const { user } = useUserContext();
  const isMember = !!user.workspaces?.some(({ _id }) => _id === slug);
  const isGuest = !!user.guestOfWorkspaces?.some(({ _id }) => _id === slug);

  const result = useWorkspace(slug, {
    skip: !slug,
    fetchPolicy: "cache-and-network",
    // Without this next fetch policy, any calls to useTeam in a component wrapped by this Provider would need to be cache-first or else...
    // ...fall into an infinite loop due to the default cache-and-network fetch policy.
    nextFetchPolicy: "cache-first",
  });

  // Analytics:
  const [isGrouped, setIsGrouped] = useState(false);
  const { group } = useAnalytics();
  const workspaceName = result.context?.name;
  useEffect(() => {
    if (isMember && !isGrouped && workspaceName) {
      group(slug, workspaceName ? { name: workspaceName } : {});
      setIsGrouped(true);
    }
  }, [isMember, isGrouped, group, slug, workspaceName]);

  let workspaceCtx;
  const baseWorkspaceCtx = {
    slug,
    isMember,
    isGuest,
    state: result.state,
  };

  switch (result.state) {
    case "IDLE":
    case "LOADING":
    case "SUCCESS":
      const workspace = result.context;
      const isAdmin = !!workspace?.allMembersGroup.members.some(
        (m) => m.user._id === user?._id && m.permission === GroupRole.Admin
      );
      const isBillingAdmin = !!workspace?.billingAdmins.some(
        (billingAdmin) => billingAdmin._id === user?._id
      );

      workspaceCtx = {
        ...baseWorkspaceCtx,
        workspace,
        isAdmin,
        isBillingAdmin,
      };
      break;
    case "FAILURE":
    default:
      workspaceCtx = {
        ...baseWorkspaceCtx,
        workspace: undefined,
        isAdmin: false,
        isBillingAdmin: false,
      };
      break;
  }

  return (
    <WorkspaceContext.Provider value={workspaceCtx}>
      {children}
    </WorkspaceContext.Provider>
  );
};

export const useWorkspaceContext = () => useContext(WorkspaceContext);
