import { gql } from "@apollo/client";
import type { TypedDocumentNode } from "@apollo/client";

import type {
  GetWorkspaceQuery,
  GetWorkspaceQueryVariables,
} from "~/graphql/generated";

export const GET_WORKSPACE_LINK_INVITATION: TypedDocumentNode<
  GetWorkspaceQuery,
  GetWorkspaceQueryVariables
> = gql`
  query GetWorkspaceInvitationLink($params: WorkspaceInvitationLinkParams!) {
    workspaceInvitationLink(params: $params) {
      linkId
      expires
      workspace {
        _id
      }
    }
  }
`;
