import styled from "styled-components";

import HorizontalEllipsisIcon from "~/assets/icons/ellipsis-horizontal.svg";
export const Container = styled(HorizontalEllipsisIcon)`
  color: ${({ theme }) => theme.colors.santasGray};
  width: 20px;
  height: 20px;
  &:hover {
    color: ${({ theme }) => theme.colors.oil};
    cursor: pointer;
  }
`;
