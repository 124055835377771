import { gql } from "@apollo/client";

import { FULL_MEMBER_FRAGMENT } from "~/graphql/fragments/member";

import { WORKSPACE_FRAGMENT } from "./workspace";

export const POPULATED_GROUP_FRAGMENT = gql`
  fragment PopulatedGroup on Group {
    _id
    name
    icon
    type
    description
    visibility
    notificationPreferences {
      type
      enabled
      channelId
      channelUrl
    }
    workspace {
      ...Workspace
    }
    members {
      ...FullMember
    }
  }

  ${FULL_MEMBER_FRAGMENT}
  ${WORKSPACE_FRAGMENT}
`;

export const GROUP_FRAGMENT = gql`
  fragment Group on Group {
    _id
    name
    icon
    type
    description
    visibility
    workspace {
      ...Workspace
    }
    members {
      _id
      permission
      user {
        _id
      }
    }
  }

  ${WORKSPACE_FRAGMENT}
`;
