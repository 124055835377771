import { gql } from "@apollo/client";

import { FULL_MEMBER_FRAGMENT } from "~/graphql/fragments/member";

export const WORKSPACE_FRAGMENT = gql`
  fragment Workspace on Workspace {
    _id
    createdAt
    name
    integrationsInstalled
    integrationsRequiringReinstall
    logoFileId
    cohortId
    guests {
      avatar
      displayName
      _id
      email
      lastActive
      joined
    }
    invitedEmails {
      email
      workspaceGuest
    }
    billingAdmins {
      _id
    }
    allMembersGroup {
      _id
      name
      members {
        ...FullMember
      }
    }
  }

  ${FULL_MEMBER_FRAGMENT}
`;
