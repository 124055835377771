import type { QueryHookOptions } from "@apollo/client";

import type { LinkInvitation } from "~/graphql/generated";
import { GET_WORKSPACE_LINK_INVITATION } from "~/graphql/queries/workspace/getLinkInvitation";
import useQueryMachine from "~/hooks/useQueryMachine";

export const useWorkspaceLinkInvitation = ({
  slug,
  guest,
  options = {},
}: {
  slug: string;
  guest?: boolean;
  options?: QueryHookOptions;
}) => {
  // TODO handle unauthorized responses from the backend
  const result = useQueryMachine<{ workspaceInvitationLink: LinkInvitation }>(
    GET_WORKSPACE_LINK_INVITATION,
    {
      ...options,
      variables: {
        params: { workspaceId: slug, guest: !!guest },
      },
      fetchPolicy: "network-only",
      skip: !slug,
    }
  );

  return result;
};
