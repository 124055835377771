import { useUserContext } from "~/contexts/UserContext";

export const useUserInWorkspace = ({
  workspaceId,
}: {
  workspaceId: string;
}) => {
  const { user } = useUserContext();

  const workspacesToCheck = user.workspaces ?? [];

  const userInWorkspace = workspacesToCheck.some(
    (workspace) => workspace._id === workspaceId
  );

  const userIsGuestInWorkspace = user.guestOfWorkspaces?.some(
    (workspace) => workspace._id === workspaceId
  );

  return [userInWorkspace, userIsGuestInWorkspace];
};
