import { FC } from "react";
import styled, { css, useTheme } from "styled-components";

import CheckIcon from "~/assets/icons/check-stroke.svg";
import ClipboardIcon from "~/assets/icons/copy-to-clipboard.svg";

const CopyText = styled.div<{
  copied: boolean;
  textColor: string;
  highlightColor: string;
}>`
  position: relative;
  width: 4.625rem;
  transition: color 300ms ease-in-out;
  color: ${({ copied, highlightColor, textColor }) =>
    copied ? highlightColor : textColor};
`;

export const CopyToClipboardIcon: FC<{
  copied: boolean;
  text?: string;
  textColor?: string;
  highlightColor?: string;
}> = ({
  copied,
  text = "Click to copy",
  textColor,
  highlightColor,
  ...props
}) => {
  const theme = useTheme();
  textColor = textColor || theme.colors.oil;
  highlightColor = highlightColor || theme.colors.speech;
  return (
    <span
      css={css`
        display: inline-flex;
        align-items: center;
        gap: 0.25rem;
        cursor: pointer;
        text-align: left;
      `}
      {...props}
    >
      <div
        css={css`
          position: relative;
          width: 1rem;
          height: 1rem;
        `}
      >
        <ClipboardIcon
          css={css`
            color: ${textColor};
            position: absolute;
            width: 1rem;
            height: 1rem;
            top: 0;
            left: 0;
            stroke-dasharray: 50;
            stroke-dashoffset: ${copied ? -50 : 0};
            transition: stroke-dashoffset 300ms ease-in-out;
          `}
        />
        <CheckIcon
          css={css`
            color: ${highlightColor};
            position: absolute;
            width: 1rem;
            height: 1rem;
            top: 0;
            left: 0;
            stroke-dasharray: 50;
            stroke-dashoffset: ${copied ? 0 : 50};
            transition: stroke-dashoffset 300ms ease-in-out;
          `}
        />
      </div>
      <CopyText
        textColor={textColor}
        highlightColor={highlightColor}
        copied={copied}
      >
        {copied ? "Copied" : text}
      </CopyText>
    </span>
  );
};
