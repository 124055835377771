import type { Identified } from "~/server/utils/ref";
import { refString } from "~/server/utils/refString";

export const isUserBillingAdmin = (
  workspace: { billingAdmins?: Identified[] } | undefined = undefined,
  userId?: string
) => {
  const billingAdmins = workspace?.billingAdmins;
  const isBillingAdmin = Boolean(
    billingAdmins?.some((user) => refString(user) === refString(userId))
  );
  return isBillingAdmin;
};

export const workspaceLogoFileURL = (logoFileId: string) => {
  return `https://uploads.murmur.com/${logoFileId}`;
};
