import styled from "styled-components";

import { resetMargin, resetPadding } from "~/styles/resets";

export const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const MenuButton = styled.button<{ selected: boolean }>`
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.moonLight : theme.colors.white};
  border-width: 0;
  cursor: pointer;
  display: block;
  width: 100%;
  ${resetMargin};
  padding-top: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  text-align: left;
  font-size: 0.9rem;
  font-weight: ${({ theme }) => theme.weights.regular};
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.moonLight};
  }
`;

export const Menu = styled.ul<{ zIndex?: number }>`
  ${resetMargin};
  ${resetPadding};
  position: relative;
  z-index: ${({ zIndex }) => zIndex || 10};
  width: 15rem;
  list-style: none;
  box-shadow: 0px 0px 10px ${({ theme }) => theme.colors.moon};
  background: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
`;

export const MenuItem = styled.li`
  border-bottom: 1px solid ${({ theme }) => theme.colors.moon};

  &:last-child {
    border-bottom: none;
  }

  a {
    display: block;
    padding-top: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.oil};
  }
`;

export const IconButton = styled.button`
  background: none;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
`;
