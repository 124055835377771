import type { WorkspaceFragment } from "~/graphql/generated";
import { normalizeGroupMember } from "~/model/group";
import type { WithoutNulls } from "~/utils/withoutNulls";

export const normalizeWorkspace = (input: WithoutNulls<WorkspaceFragment>) =>
  ({
    _id: input._id,
    name: input.name,
    invitedEmails: input.invitedEmails,
    billingAdmins: input.billingAdmins,
    logoFileId: input.logoFileId,
    cohortId: input.cohortId || undefined,
    guests: input.guests?.map((guest) => ({
      lastActive: guest.lastActive ? new Date(guest.lastActive) : undefined,
      _id: guest._id,
      email: guest.email,
      avatar: guest.avatar,
      displayName: guest.displayName ?? undefined,
    })),
    allMembersGroup: {
      ...input.allMembersGroup,
      members: input.allMembersGroup.members.map(normalizeGroupMember),
    },
    integrationsInstalled: input.integrationsInstalled,
    integrationsRequiringReinstall: input.integrationsRequiringReinstall,
    createdAt: new Date(input.createdAt),
  } as const);

export type Workspace = ReturnType<typeof normalizeWorkspace>;
