import { FC, useCallback, useState } from "react";
import styled, { css } from "styled-components";

import Button from "~/components/Button";
import { EmailInvite, GroupRole } from "~/graphql/generated";
import { validateEmailAddress } from "~/utils/validators/email";

export const emailInputIsValid = (str: string) => {
  const emailInputs = str.split(",");
  return emailInputs.map(validateEmailAddress).every((valid) => !!valid);
};

const EmailList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  gap: 0.25rem;
  list-style: none;
  padding-inline-start: 0;
`;

const EmailLI = styled.li`
  font-size: 0.875rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.colors.moonLight};
  & select {
    background: none;
    border: none;
    padding: 0.25rem;
  }
`;

type MemberType = Exclude<GroupRole, GroupRole.Admin>;
const EmailListItem: FC<{
  memberType: MemberType;
  onMemberTypeChange: (type: MemberType) => void;
}> = ({ memberType, onMemberTypeChange, children }) => {
  const handleMemberTypeChange = useCallback(
    (evt) => {
      const type =
        evt.target.value === "member" ? GroupRole.Member : GroupRole.Guest;
      onMemberTypeChange?.(type);
    },
    [onMemberTypeChange]
  );
  return (
    <EmailLI>
      <div>{children}</div>
      <div>
        <select
          value={memberType === GroupRole.Guest ? "guest" : "member"}
          onChange={handleMemberTypeChange}
        >
          <option value="guest">Guest</option>
          <option value="member">Member</option>
        </select>
      </div>
    </EmailLI>
  );
};

const EmailInput: FC<{
  emails: Map<string, EmailInvite>;
  onChange: (emails: Map<string, EmailInvite>) => void;
  disabled?: boolean;
}> = ({ emails, onChange, disabled }) => {
  const [emailInput, setEmailInput] = useState<string>("");

  const handleEmailInputChange = useCallback((evt) => {
    const value = evt.target.value;
    setEmailInput(value);
  }, []);

  const handleSubmit = useCallback(
    (evt) => {
      evt.preventDefault();
      evt.stopPropagation();
      const emailInputs = emailInput.split(",");
      const isValid = emailInputIsValid(emailInput);
      if (isValid) {
        setEmailInput("");
        onChange(
          new Map([
            ...emails.entries(),
            ...emailInputs.map<[string, EmailInvite]>((email) => [
              email,
              { email, workspaceGuest: false },
            ]),
          ])
        );
      }
    },
    [emailInput, emails, onChange]
  );

  const handleTypeChange = useCallback(
    (change) => {
      onChange(new Map([...emails.entries(), [change.email, change]]));
    },
    [emails, onChange]
  );

  const addButtonDisabled = disabled || !emailInputIsValid(emailInput);

  return (
    <form onSubmit={handleSubmit}>
      <div
        css={css`
          display: flex;
          gap: 0.5rem;
          margin: 1rem 0;
        `}
      >
        <input
          disabled={disabled}
          id="emailAddresses"
          type="text"
          autoComplete="off"
          css={css`
            display: block;
            width: 100%;
            padding: 0.5rem;
            border-radius: 0.25rem;
            border: 1px solid ${({ theme }) => theme.colors.moonMedium};
            &::placeholder {
              font-size: 0.875rem;
            }
          `}
          value={emailInput}
          onChange={handleEmailInputChange}
          placeholder="Type email addresses, separated by commas"
        />
        <Button
          type="submit"
          disabled={addButtonDisabled}
          css={css`
            width: auto;
            padding: 0 1rem;
          `}
        >
          Add
        </Button>
      </div>
      <div></div>
      <EmailList>
        {Array.from(emails.entries()).map(([, { email, workspaceGuest }]) => (
          <EmailListItem
            key={email}
            memberType={workspaceGuest ? GroupRole.Guest : GroupRole.Member}
            onMemberTypeChange={(type) => {
              handleTypeChange({
                email,
                workspaceGuest: type === GroupRole.Guest,
              });
            }}
          >
            {email}
          </EmailListItem>
        ))}
      </EmailList>
    </form>
  );
};
export default EmailInput;
