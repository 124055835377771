import type { ApolloError } from "@apollo/client";
import { useQuery } from "@apollo/client";
import type { OperationVariables } from "@apollo/client/core";
import type { QueryHookOptions } from "@apollo/client/react/types/types";
import type { TypedDocumentNode } from "@graphql-typed-document-node/core";
import type { DocumentNode } from "graphql";
import { useEffect, useState } from "react";

const STOP_POLLING_AFTER_NUMBER_OF_ERRORS = 5;

type Result<TData> =
  | { state: "IDLE"; context: undefined }
  | { state: "LOADING"; context: undefined | TData }
  | { state: "SUCCESS"; context: TData }
  | { state: "FAILURE"; context: ApolloError };

export default function useQueryMachine<
  TData = unknown,
  TVariables = OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  { ssr, fetchPolicy, ...options }: QueryHookOptions<TData, TVariables> = {}
): Result<TData> {
  const { data, error, loading, stopPolling } = useQuery(query, {
    ssr: typeof ssr === "undefined" ? false : ssr,
    fetchPolicy: fetchPolicy ?? "cache-and-network",
    ...options,
  });
  const [result, setResult] = useState<Result<TData>>({
    state: "IDLE",
    context: undefined,
  });

  const [numberOfErrors, setNumberOfErrors] = useState(0);

  useEffect(() => {
    // necessary for polling to be effective and update the result
    if (result.state === "SUCCESS" && data) {
      setResult({ state: "SUCCESS", context: data });
      setNumberOfErrors(0);
    }
  }, [data, result.state]);

  useEffect(() => {
    if (result.state === "IDLE" && loading) {
      setResult({ state: "LOADING", context: undefined });
    }
  }, [loading, result.state]);

  useEffect(() => {
    if ((result.state === "IDLE" || result.state === "LOADING") && data) {
      setResult({ state: "SUCCESS", context: data });
      setNumberOfErrors(0);
    }
  }, [data, result.state]);

  useEffect(() => {
    if ((result.state === "IDLE" || result.state === "LOADING") && error) {
      setResult({ state: "FAILURE", context: error });
    }
  }, [error, result.state]);

  useEffect(() => {
    if (error && numberOfErrors > STOP_POLLING_AFTER_NUMBER_OF_ERRORS) {
      console.warn(
        `Stopping polling because we received ${numberOfErrors} errors, which is more than ${STOP_POLLING_AFTER_NUMBER_OF_ERRORS} for query`,
        query
      );
      stopPolling();
    }
    if (error && numberOfErrors <= STOP_POLLING_AFTER_NUMBER_OF_ERRORS) {
      setNumberOfErrors((currVal) => {
        return currVal + 1;
      });
    }
  }, [error, numberOfErrors, query, stopPolling]);
  return result;
}
