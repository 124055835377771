import styled from "styled-components";

import DefaultXIcon from "~/assets/icons/x.svg";
import DefaultButton from "~/components/Button";
import ClickAwayListener from "~/components/ClickAwayListener";

export const Button = styled(DefaultButton)`
  width: inherit;
`;

export const XIcon = styled(DefaultXIcon)`
  color: ${({ theme }) => theme.colors.saltBox};
  width: 20px;
  height: 20px;
  &:hover {
    color: ${({ theme }) => theme.colors.oil};
    cursor: pointer;
  }
`;

export const Container = styled.div`
  z-index: 10000;
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`;

export const ModalContainer = styled(ClickAwayListener)`
  padding-top: 1rem;
  padding-right: 1rem;
  padding-bottom: 4rem;
  padding-left: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 620px;
  overflow: auto;
  max-height: 100%;
  background: ${({ theme }) => theme.colors.white};
`;

export const ModalHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const ModalBody = styled.div`
  width: 100%;
`;
