import { gql } from "@apollo/client";
import type { TypedDocumentNode } from "@apollo/client";

import { WORKSPACE_FRAGMENT } from "~/graphql/fragments/workspace";
import type {
  GetWorkspaceQuery,
  GetWorkspaceQueryVariables,
} from "~/graphql/generated";

export const GET_WORKSPACE: TypedDocumentNode<
  GetWorkspaceQuery,
  GetWorkspaceQueryVariables
> = gql`
  query GetWorkspace($params: WorkspaceParams!) {
    workspace(params: $params) {
      ...Workspace
    }
  }

  ${WORKSPACE_FRAGMENT}
`;
