import type {
  FullMemberFragment,
  PopulatedGroupFragment,
} from "~/graphql/generated";

import { normalizeWorkspace } from "./workspace";

export const normalizeGroupMember = ({
  permission,
  user,
}: FullMemberFragment) => ({
  permission: permission,
  user: {
    lastActive: user.lastActive ? new Date(user.lastActive) : undefined,
    _id: user._id,
    email: user.email,
    avatar: user.avatar,
    displayName: user.displayName ?? undefined,
  },
});

export const normalizeGroup = (input: PopulatedGroupFragment) =>
  ({
    _id: input._id,
    name: input.name,
    type: input.type,
    icon: input.icon ?? "",
    description: input.description ?? "",
    members: input.members.map(normalizeGroupMember),
    workspace: normalizeWorkspace({
      ...input.workspace,
      logoFileId: input.workspace.logoFileId || undefined,
      cohortId: input.workspace.cohortId || undefined,
    }),
    visibility: input.visibility,
    notificationPreferences: input.notificationPreferences ?? [],
  } as const);

export type Group = ReturnType<typeof normalizeGroup>;
